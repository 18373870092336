<template>
    <ApplicationHeader/>
    <main class="flex-grow-1">
      <router-view />
    </main>
    <ApplicationFooter/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import ApplicationHeader from "@/components/layout/Header";
import ApplicationFooter from "@/components/layout/Footer";
export default {
  components: {ApplicationFooter, ApplicationHeader}
}
</script>