<template>
  <header class="py-3 border-bottom site-header">
    <div class="container d-flex flex-wrap justify-content-center">
      <a href="https://clv.de/" class="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-white text-decoration-none">
        <img class="bi me-2 clv-logo" height="40" src="https://static.clv.de/img/logo/clv_logo_shop_desktop.png" alt="Zur Startseite wechseln">

      </a>
      <div class="col-12 col-lg-auto mb-3 mb-lg-0 text-center text-lg-end">
        <span class="fs-4">{{ $route.name === 'home' ? 'Newsletter Anmeldung' : $route.name }}</span>
      </div>
    </div>
  </header>
  <nav class="py-2 bg-light border-bottom d-none d-sm-block">
    <div class="container d-flex flex-wrap">
      <ul class="nav me-auto">
        <li class="nav-item"><a href="https://clv.de" class="nav-link link-dark px-2">Shop</a></li>
<!--        <li class="nav-item"><a href="#" class="nav-link link-dark px-2">Newsletter Archiv</a></li>-->
      </ul>
      <ul class="nav">
        <li class="nav-item"><a href="https://clv.de/Informationen/Impressum/" class="nav-link link-dark px-2">Impressum</a></li>
        <li class="nav-item"><a href="https://clv.de/Informationen/Datenschutz/" class="nav-link link-dark px-2">Datenschutz</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "ApplicationHeader"
}
</script>

<style scoped>
.clv-logo {
  max-height: 40px;
}
</style>