<template>
  <footer class="footer text-center text-lg-start text-white mt-auto">
    <section
        class="p-4 border-bottom"
    >
      <div class="container d-flex justify-content-center justify-content-lg-between">
        <div class="me-5 d-none d-lg-block">
          <span>Verbinden Sie sich mit uns in den sozialen Netzwerken: </span>
        </div>
        <div>
          <a
              v-for="social in socials"
              :key="social.title"
              :href="social.url"
              target="_blank"
              class="me-4 text-reset"
          >
            <i :class="`fab ${ social.icon }`"></i>
          </a>
        </div>
      </div>
    </section>
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <img src="https://static.clv.de/img/logo/clv_logo_shop_desktop.png" alt="Zur Startseite wechseln"
                 class="header-logo-main-img"/>
          </div>
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <h6 class="text-uppercase fw-bold mb-4">
              Rechtliches
            </h6>
            <p v-for="(item, index) in menuItems.filter(i => i.type === 'legal')" :key="index">
              <a :href="item.url" target="_blank" class="text-reset text-decoration-none">{{ item.title }}</a>
            </p>
          </div>
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Weiteres
            </h6>
            <p v-for="(item, index) in menuItems.filter(i => i.type === 'others')" :key="index">
              <a :href="item.url" target="_blank" class="text-reset text-decoration-none">{{ item.title }}</a>
            </p>
          </div>
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <h6 class="text-uppercase fw-bold mb-4">
              Kontakt
            </h6>
            <p>Ravensberger Bleiche 6<br>33649 Bielefeld</p>
            <p>
              info@clv.de
            </p>
            <p>+ 49 (0) 521 947240</p>
          </div>
        </div>
      </div>
    </section>
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      © <a class="text-reset fw-bold text-decoration-none" href="https://clv.de/">CLV Bielefeld</a> – Bücher, die
      weiterhelfen

    </div>
  </footer>
</template>

<script>
export default {
  name: "ApplicationFooter",
  data() {
    return {
      socials: [
        {
          title: 'Facebook',
          icon: 'fa-facebook-f',
          url: 'https://www.facebook.com/clv.de',
        },
        {
          title: 'Instagram',
          icon: 'fa-instagram',
          url: 'https://www.instagram.com/clv.de/',
        },
        // {
        //   title: 'Pinterest',
        //   icon: 'fa-pinterest',
        //   url: 'https://pinterest.com',
        // },
      ],
      menuItems: [
        {
          type: 'legal',
          title: 'AGB',
          url: 'https://clv.de/Shopservice/AGB/',
        },
        {
          type: 'legal',
          title: 'Impressum',
          url: 'https://clv.de/Informationen/Impressum/',
        },
        {
          type: 'legal',
          title: 'Datenschutz',
          url: 'https://clv.de/Informationen/Datenschutz/',
        },
        {
          type: 'legal',
          title: 'Widerrufsrecht',
          url: 'https://clv.de/Shopservice/Widerrufsrecht/',
        },
        {
          type: 'others',
          title: 'Über Uns',
          url: 'https://clv.de/Informationen/UEber-Uns/',
        },
        {
          type: 'others',
          title: 'Autoren',
          url: 'https://clv.de/Autoren/',
        },
        {
          type: 'others',
          title: 'Shop',
          url: 'https://clv.de',
        },
        {
          type: 'others',
          title: 'Kontakt',
          url: 'https://clv.de/Shopservice/Kontakt/',
        },
      ]
    }
  }
}
</script>

<style scoped>
.header-logo-main-img {
  max-width: 150px;
}
</style>