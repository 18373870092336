<template>
  <div class="container text my-5 py-5">
    <div v-if="uuid && email">
      <form ref="newsletterForm" @submit.prevent="updateSubscription">
        <div v-if="error" class="alert alert-warning" role="alert">
          {{ errorMessage }}
        </div>
        <div v-if="success" class="alert alert-success" role="alert">
          {{ successMessage }}
        </div>
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <div class="form-floating mb-3">
              <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  id="inputEmail"
                  placeholder="ihre.email@beispiel.de"
                  required
                  disabled
              >
              <label for="inputEmail">E-Mail*</label>
            </div>
            <div class="form-floating mb-3">
              <select v-model="selectedGender" class="form-select" id="selectGender" aria-label="selectGender">
                <option selected :value="null" disabled>Wählen Sie Ihre Anrede aus</option>
                <option v-for="option in genderOptions" :key="option.id" :value="option.id">{{ option.title }}</option>
              </select>
              <label for="selectGender">Anrede</label>
            </div>
            <div class="form-floating mb-3">
              <input v-model="firstName" type="text" class="form-control" id="inputFirstName" placeholder="Vorname">
              <label for="inputFirstName">Vorname</label>
            </div>
            <div class="form-floating mb-3">
              <input v-model="lastName" type="text" class="form-control" id="inputLastName" placeholder="Nachname">
              <label for="inputLastName">Nachname</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <p>
              Hier können Sie Ihr Newsletter-Abonnement verwalten. Geben Sie gerne Ihre aktuellen Daten an und
              wählen Sie die gewünschten Newsletter aus. Ihre gespeicherten Daten werden aus Datenschutzgründen hier nicht
              angezeigt. Nur wenn Sie Angaben machen, werden Ihre Daten für dieses Feld bei uns aktualisiert.
            </p>
            <p v-if="lists.length === 1">
              Sobald Sie auf "Daten ändern" klicken, melden wir Sie mit Ihren eingegebenen Daten zum Newsletter
              "{{ lists[0].name }}" an.
            </p>
            <div v-else>
              <p>
                <b>Bitte wählen Sie die Newsletter, die Sie erhalten möchten:</b>
              </p>
              <div v-for="(list, index) in lists" :key="list.id" class="form-check form-switch">
                <input
                    v-model="lists[index].selected"
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    :id="`list-${ list.id }`"
                    :disabled="lists[index].selected && lists.filter(l => l.selected).length <= 1"
                >
                <label class="form-check-label" :for="`list-${ list.id }`">
                  {{ list.name }}<br>
                  <small class="text-muted">{{ list.description }}</small>
                </label>
              </div>
            </div>
            <div class="d-grid gap-2 my-4">
              <button
                  type="submit"
                  class="btn btn-primary block"
              >
                Daten ändern
              </button>
            </div>
            <div class="text-muted small">
              <p>
                Weitere technische Informationen zu unserem Newsletter finden Sie in unserer Datenschutzerklärung.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="text-center">
      <h2>Abonnement verwalten</h2>
      <p>Um Ihr Abonnement zu bearbeiten, müssen Sie den entsprechenden Link im Newsletter anklicken.</p>

      <a href="https://clv.de" class="btn btn-primary my-3">zum CLV Shop</a>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
  name: "ManageView",
  setup() {
    const store = useStore()
    return {
      lists: computed(() => store.state.lists)
    }
  },
  data() {
    return {
      uuid: '',
      email: '',
      selectedLists: [],
      genderOptions: [
        {id: 1, title: 'Herr'},
        {id: 2, title: 'Frau'},
        {id: 3, title: 'Keine Angabe'},
      ],
      selectedGender: null,
      firstName: '',
      lastName: '',
      gdprAccept: false,
      type: 0,
      affiliate: null,
      campaign: null,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
      presetLists: [],
    }
  },
  async mounted() {
    await this.getLists()
    await this.processUrlParameters()
    if (this.forceUpdate) {
      await this.updateSubscription()
    }
  },
  methods: {
    async processUrlParameters() {
      this.uuid = this.$route.query.uuid || ''
      this.email = this.$route.query.email || ''
      this.firstName = this.$route.query.fname || ''
      this.lastName = this.$route.query.lname || ''
      this.selectedGender = this.$route.query.gender || null
      this.forceUpdate = this.$route.query.update === 'instant'
      this.presetLists = this.$route.query['lists[]'] || null

      if (this.presetLists !== null) {
        await this.matchLists()
      }
      await this.deleteUrlQuery()
    },
    async matchLists() {
      for (let i = 0; i < this.lists.length; i++) {
        if (typeof this.presetLists === 'object')
          this.lists[i].selected = this.presetLists.includes(this.lists[i].id.toString());
        else {
          this.lists[i].selected = parseInt(this.presetLists) === this.lists[i].id;
        }
      }
      return true
    },
    async deleteUrlQuery() {
      const query = {}
      await this.$router.replace({query})
    },
    async getLists() {
      await this.$store.dispatch('getLists')
    },
    async updateSubscription() {
      if (this.lists.filter(l => l.selected === true).length >= 1 && this.email !== '' && this.uuid !== '') {
        let userData = {
          uuid: this.uuid,
          email: this.email,
          data: {
            lists: this.lists.filter(l => l.selected === true).map(l => l.id),
          }
        }
        if (this.firstName !== '') userData.data.first_name = this.firstName
        if (this.lastName !== '') userData.data.last_name = this.lastName
        if (this.selectedGender !== null) userData.data.gender = this.selectedGender
        if (await this.$store.dispatch('update', userData)) {
          this.error = false
          this.errorMessage = ''
          this.success = true
          this.successMessage = 'Vielen Dank, wir haben Ihre Daten aktualisiert.'
          this.resetForm()
        } else {
          this.error = true
          this.errorMessage = 'Hier ist etwas schief gelaufen, versuchen Sie es bitte später noch einmal.'
        }
      } else {
        this.error = true
        this.errorMessage = 'Bitte füllen Sie alle Pflichtfelder aus.'
      }
    },
    resetForm() {
      this.firstName = ''
      this.lastName = ''
      this.selectedGender = null
      this.gdprAccept = false
      this.affiliate = null
      this.campaign = null
    },
  }
}
</script>

<style scoped>

</style>