<template>
  <div class="container text my-5 py-5 text-center">
    <div v-if="uuid && email">
      <div v-if="error" class="alert alert-warning" role="alert">
        {{ errorMessage }}
      </div>
      <div v-if="success" class="alert alert-success" role="alert">
        {{ successMessage }}
      </div>
      <h2>Newsletter-Abmeldung</h2>
      <p>Schade, dass Sie unseren Newsletter nicht mehr bekommen möchten.</p>
      <div v-if="!success">
        <p>
          Sie können Sich entweder vollständig von vom Newsletter abmelden oder nur bestimmte Listen entfernen um nur das
          zu erhalten, was Sie wirklich interessiert.
        </p>

        <button class="btn btn-primary m-1" href="#" role="button" @click="unsubscribe">Vollständig abmelden</button>
        <router-link :to="`/manage?uuid=${uuid}&email=${email}`">
          <button class="btn btn-outline-primary">
            Liste abwählen
          </button>
        </router-link>
      </div>
      <div v-else>
        <p>
          Wir haben Ihre Adresse vollständig von unseren Listen abgemeldet. Sie erhalten keine weiteren Newsletter mehr von uns. Sollten Sie es sich doch nochmal anders überlegen, können Sie sich jederzeit wieder erneut anmelden.
        </p>
      </div>
    </div>
    <div v-else>
      <h2>Newsletter Abmeldung</h2>
      <p>Schade, dass Sie unseren Newsletter nicht mehr erhalten möchten.</p>
      <p>Bitte klicken Sie auf den Abmelden-Link in dem Newsletter, den Sie erhalten haben.</p>

      <a href="https://clv.de" class="btn btn-primary my-3">zum CLV Shop</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnsubscribeView",
  data() {
    return {
      uuid: false,
      email: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
    }
  },
  mounted() {
    this.processUrlParameters()
  },
  methods: {
    processUrlParameters() {
      this.uuid = this.$route.query.uuid || false
      this.email = this.$route.query.email || false
      this.deleteUrlQuery()
    },
    async deleteUrlQuery() {
      const query = {}
      await this.$router.replace({query})
    },
    async unsubscribe() {
      if (this.uuid && this.email) {
        let userData = {
          email: this.email,
          uuid : this.uuid
        }
        if (await this.$store.dispatch('unsubscribe', userData )) {
          this.success = true
          this.successMessage = 'Wir haben Sie vom Newsletter abgemeldet.'
        } else {
          this.error = true
          this.errorMessage = 'Hier ist etwas schief gelaufen, versuchen Sie es bitte später noch einmal.'
        }
      }
    },
  }
}
</script>

<style scoped>

</style>