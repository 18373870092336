<template>
  <div>
    <div id="myModal" :class="`modal ${show ? 'show':'hide'}`" @click="close">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="mt-2">{{ campaign.name }}</h2>
          <span class="close">&times;</span>
        </div>
        <div class="modal-body">
          <div v-html="campaign.html_content"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    campaignId: Number,
    show: Boolean
  },
  data() {
    return {
      campaign: {}
    }
  },
  watch: {
    async show(visible) {
      if (visible)
        await this.getCampaign()
    }
  },
  methods: {
    async getCampaign() {
      this.campaign = await this.$store.dispatch('getCampaign', this.campaignId)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal.show {
  display: block;
}

.modal.hide {
  display: none;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 0 auto 50px;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  max-width: 1000px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 10px 16px;
  background-color: #498392;
  color: white;
}

.modal-body {
  padding: 0;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #498392;
  color: white;
}
</style>