<template>
  <div>
    <div class="position-relative overflow-hidden p-1 p-md-2 mb-md-5 text-center bg-light">
      <div class="col-md-6 p-lg-3 mx-auto my-5">
        <h1 class="display-4 font-weight-normal">CLV Newsletter-Archiv</h1>
        <p class="lead font-weight-normal">
          Lesen Sie unseren Newsletter, auch ganz ohne Abonnement.
        </p>
        <router-link class="btn btn-primary" to="/subscribe">Newsletter jetzt abonnieren</router-link >
      </div>
      <div class="product-device box-shadow d-none d-md-block"></div>
      <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
    </div>
    <div class="container text my-5 py-5 text-center">
      <table class="table table-hover text-start align-middle table-striped table-responsive">
        <thead>
        <tr>
          <th style="width: 1%; white-space: nowrap">Datum</th>
          <th>Newsletter</th>
          <th class="text-end">Ansicht</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="campaign in campaigns.filter(c => c.queue_generated_at !== null).sort(function(a,b){return new Date(b.queue_generated_at) - new Date(a.queue_generated_at);})" :key="campaign.id" @click="openModal(campaign.id)">
          <td style="width: 1%; white-space: nowrap; padding-right: 30px !important;">{{ getFormattedDate(campaign.queue_generated_at) }}</td>
          <td><strong>{{ campaign.name }}</strong></td>
          <td class="text-end">
            <button class="btn btn-primary" @click="openModal(campaign.id)">Ansehen</button>
          </td>
        </tr>
        </tbody>
      </table>
      <ModalBox :campaignId="campaignId" :show="show" @close="closeModal"/>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import ModalBox from "@/components/ModalBox";

export default {
  name: "ArchiveView",
  components: {ModalBox},
  setup() {
    const store = useStore()
    return {
      campaigns: computed(() => store.state.campaigns)
    }
  },
  data() {
    return {
      loading: true,
      campaignId: null,
      show: false,
      newsletter: false,
    }
  },
  async mounted() {
    await this.getCampaigns()
    await this.processUrlParameters()
  },
  methods: {
    async getCampaigns() {
      await this.$store.dispatch('getCampaigns')
    },
    processUrlParameters() {
      this.newsletter = this.$route.query.newsletter || false
      this.deleteUrlQuery()
      if (this.newsletter && this.campaigns.some(c => c.id === Number(this.newsletter))) {
        this.campaignId = Number(this.newsletter)
        this.show = true
      }
    },
    async deleteUrlQuery() {
      const query = {}
      await this.$router.replace({query})
    },
    openModal(id) {
      this.campaignId = id
      this.show = true
    },
    closeModal() {
      this.campaignId = null
      this.show = false
    },
    getFormattedDate(datetime){
      let date  = new Date(datetime);
      return date.toLocaleDateString("de-DE", { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' })
    }
  },
}
</script>
