<template>
  <div class="container text my-5 py-5">
    <form ref="newsletterForm" @submit.prevent="subscribe">
      <div v-if="error" class="alert alert-warning" role="alert">
        {{ errorMessage }}
      </div>
      <div v-if="success" class="alert alert-success" role="alert">
        {{ successMessage }}
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <div class="form-floating mb-3">
            <input
                v-model="email"
                type="email"
                class="form-control"
                id="inputEmail"
                placeholder="ihre.email@beispiel.de"
                required
            >
            <label for="inputEmail">E-Mail*</label>
          </div>
          <div class="form-floating mb-3">
            <select v-model="selectedGender" class="form-select" id="selectGender" aria-label="selectGender">
              <option selected :value="null" disabled>Wählen Sie Ihre Anrede aus</option>
              <option v-for="option in genderOptions" :key="option.id" :value="option.id">{{ option.title }}</option>
            </select>
            <label for="selectGender">Anrede</label>
          </div>
          <div class="form-floating mb-3">
            <input v-model="firstName" type="text" class="form-control" id="inputFirstName" placeholder="Vorname">
            <label for="inputFirstName">Vorname</label>
          </div>
          <div class="form-floating mb-3">
            <input v-model="lastName" type="text" class="form-control" id="inputLastName" placeholder="Nachname">
            <label for="inputLastName">Nachname</label>
          </div>
          <div class="form-check">
            <input
                v-model="gdprAccept"
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                required
            >
            <label class="form-check-label" for="flexCheckDefault">
              Ich stimme dem Erhalt dieses Newsletters zu und weiß, dass ich mich jederzeit problemlos abmelden kann.*
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <p v-if="lists.length === 1">
            Sobald Sie auf "Jetzt Anmelden" klicken, melden wir Sie zum Newsletter "{{ lists[0].name }}" an.
          </p>
          <div v-else>
            <p>
              <b>Bitte wählen Sie die Newsletter, die Sie erhalten möchten:</b>
            </p>
            <div v-for="(list, index) in lists" :key="list.id" class="form-check form-switch mb-2">
              <input
                  v-model="lists[index].selected"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  :id="`list-${ list.id }`"
                  :disabled="lists[index].selected && lists.filter(l => l.selected).length <= 1"
              >
              <label class="form-check-label" :for="`list-${ list.id }`">
                {{ list.name }}<br>
                <small class="text-muted">{{ list.description }}</small>
              </label>
            </div>
          </div>
          <div class="d-grid gap-2 my-4">
            <button
                type="submit"
                class="btn btn-primary block"
            >
              Jetzt anmelden
            </button>
          </div>
          <div class="text-muted small">
            <p>
              Sie erhalten nach dem Absenden des Formulars eine Bestätigungsmail, in der Sie das Abonnement
              bestätigen
              müssen. Danach erhalten Sie die gewünschten Newsletter.
            </p>
            <p>
              Weitere technische Informationen zu unserem Newsletter finden Sie in unserer Datenschutzerklärung.
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
  name: "SubscribeView",
  setup() {
    const store = useStore()
    return {
      lists: computed(() => store.state.lists)
    }
  },
  data() {
    return {
      email: '',
      selectedLists: [],
      genderOptions: [
        {id: 1, title: 'Herr'},
        {id: 2, title: 'Frau'},
        {id: 3, title: 'Keine Angabe'},
      ],
      selectedGender: null,
      firstName: '',
      lastName: '',
      gdprAccept: false,
      type: 0,
      affiliate: null,
      campaign: null,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
    }
  },
  mounted() {
    this.processUrlParameters()
    this.getLists()
  },
  methods: {
    processUrlParameters() {
      this.email = this.$route.query.email || ''
      this.firstName = this.$route.query.fname || ''
      this.lastName = this.$route.query.lname || ''
      this.selectedGender = this.$route.query.gender || null
      this.affiliate = this.$route.query.affiliate || null
      this.campaign = this.$route.query.campaign || null
      this.deleteUrlQuery()
    },
    async deleteUrlQuery() {
      const query = {}
      await this.$router.replace({query})
    },
    getLists() {
      this.$store.dispatch('getLists')
    },
    async subscribe() {
      if (this.lists.filter(l => l.selected === true).length >= 1 && this.email !== '' && this.gdprAccept) {
        let userData = {
          email: this.email,
          accepted_gdpr: this.gdprAccept,
          lists: this.lists.filter(l => l.selected === true).map(l => l.id),
        }
        if (this.firstName !== '') userData.first_name = this.firstName
        if (this.lastName !== '') userData.last_name = this.lastName
        if (this.selectedGender !== null) userData.gender = this.selectedGender
        if (this.affiliate !== null) userData.affiliate = this.affiliate
        if (this.campaign !== null) userData.campaign = this.campaign
        if (await this.$store.dispatch('subscribe', userData)) {
          this.success = true
          this.successMessage = 'Vielen Dank! Sie bekommen von uns eine Bestätigungsmail.'
          this.resetForm()
        } else {
          this.error = true
          this.errorMessage = 'Hier ist etwas schief gelaufen, versuchen Sie es bitte später noch einmal.'
        }
      } else {
        this.error = true
        this.errorMessage = 'Bitte füllen Sie alle Pflichtfelder aus.'
      }
    },
    resetForm() {
      this.email = ''
      this.firstName = ''
      this.lastName = ''
      this.selectedGender = null
      this.gdprAccept = false
      this.affiliate = null
      this.campaign = null
    },
  }
}
</script>

<style scoped>

</style>