import {createRouter, createWebHistory} from 'vue-router'
import SubscribeView from '@/views/SubscribeView.vue'
import UnsubscribeView from '@/views/UnsubscribeView.vue'
import ManageView from '@/views/ManageView.vue'
import DoubleOptInView from '@/views/DoubleOptInView.vue'
import ArchiveView from "@/views/ArchiveView";

const routes = [
    {
        path: '/',
        name: 'home',
        component: SubscribeView
    },
    {
        path: '/subscribe',
        name: 'Newsletter Anmeldung',
        component: SubscribeView
    },
    {
        path: '/unsubscribe',
        name: 'Newsletter Abmeldung',
        component: UnsubscribeView
    },
    {
        path: '/manage',
        name: 'Newsletter Abo',
        component: ManageView
    },
    {
        path: '/opt-in',
        name: 'Newsletter Opt-In',
        component: DoubleOptInView
    },
    {
        path: '/archiv',
        name: 'Newsletter Archiv',
        component: ArchiveView
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
