<template>
  <div class="container text my-5 py-5 text-center">
    <div v-if="error || success">
      <div v-if="error">
        <div class="alert alert-warning" role="alert">
          {{ errorMessage }}
        </div>
      </div>
      <div v-if="success">
        <div class="alert alert-success" role="alert">
          {{ successMessage }}
        </div>
        <h2>Newsletter Anmeldung</h2>
        <p>Vielen Dank für Ihre Anmeldung. Sie erhalten mit dem nächsten Versand Ihren Newsletter zugestellt.</p>
      </div>
    </div>
    <div v-else-if="!loading">
      <h2>Newsletter-Anmeldung</h2>
      <p>Wir verarbeiten gerade Ihre Bestätigung.</p>
      <p>Sollte das länger dauern, versuchen Sie es bitte später noch einmal. Klicken Sie dazu erneut auf den Link in Ihrer E-Mail.</p>

      <a href="https://clv.de" class="btn btn-primary my-3">zum CLV Shop</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoubleOptInView",
  data() {
    return {
      uuid: false,
      email: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
      loading: true,
    }
  },
  async mounted() {
    await this.processUrlParameters()
    await this.optIn()
  },
  methods: {
    processUrlParameters() {
      this.uuid = this.$route.query.uuid || false
      this.email = this.$route.query.email || false
      this.deleteUrlQuery()
    },
    async deleteUrlQuery() {
      const query = {}
      await this.$router.replace({query})
    },
    async optIn(){
      if (this.uuid && this.email) {
        let userData = {
          email: this.email,
          uuid : this.uuid
        }
        if (await this.$store.dispatch('confirm', userData )) {
          this.success = true
          this.successMessage = 'Wir haben Sie für den Newsletter angemeldet.'
        } else {
          this.error = true
          this.errorMessage = 'Hier ist etwas schief gelaufen, versuchen Sie es bitte später noch einmal.'
        }
      }
      else {
        this.error = true
        this.errorMessage = 'Hier ist etwas schief gelaufen, versuchen Sie es bitte später noch einmal.'
      }
      this.loading = false
    },
  },
}
</script>
