import {createStore} from 'vuex'

const apiUrl = 'https://api.clv.de'
const recipientsEndpoint = '/newsletter_recipients'

async function getData(url) {
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'content-type': 'application/json'
        }
    })
    return await response.json();
}

async function postData(url, data) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })
    return await response.json();
}

export default createStore({
    state: {
        lists: [],
        subscription: {},
        campaigns: [],
        currentCampaign: {},
    },
    getters: {},
    mutations: {
        setLists(state, lists) {
            state.lists = lists.map(l => ({...l, selected: true}))
        },
        setSubscription(state, userData) {
            state.subscription = userData
        },
        setCampaigns(state, campaigns) {
            state.campaigns = campaigns
        },
        setCurrentCampaign(state, campaign) {
            state.currentCampaign = campaign
        }
    },
    actions: {
        async getLists({commit}) {
            const data = await getData(`${apiUrl}/newsletter_lists/`)
            await commit('setLists', data)
            return true
        },

        async subscribe({commit}, data) {
            const url = `${apiUrl}${recipientsEndpoint}/`
            const subscription = await postData(url, data)
            if (subscription.email) {
                await commit('setSubscription', subscription)
                return true
            }
            return subscription === 'Data change confirmation mail sent.';
        },

        async confirm({commit}, data) {
            let url = `${apiUrl}${recipientsEndpoint}/double_opt_in/`
            const result = await postData(url, data)
            if (result.success) {
                await commit('setSubscription', {})
                return true
            }
            return false
        },

        async update({commit}, data) {
            const url = `${apiUrl}${recipientsEndpoint}/update_data/`
            const subscription = await postData(url, data)
            if (subscription.email) {
                await commit('setSubscription', subscription)
                return true
            }
            return false
        },

        async unsubscribe({commit}, data) {
            const url = `${apiUrl}${recipientsEndpoint}/unsubscribe/`
            const result = await postData(url, data)
            if (result.success) {
                await commit('setSubscription', {})
                return true
            }
            return false
        },

        async getCampaigns({commit}) {
            const data = await getData(`${apiUrl}/newsletter_campaigns/`)
            await commit('setCampaigns', data)
            return true
        },

        async getCampaign({commit}, id) {
            const campaign = getData(`${apiUrl}/newsletter_campaigns/${id}/`)
            await commit('setCurrentCampaign', campaign)
            return campaign
        }
    },
    modules: {}
})
